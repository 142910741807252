// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from "react";

import {
	ColumnLayout,
	ContentLayout,
	Grid,
	Link,
} from "@cloudscape-design/components";
import {SegmentConfig} from "../../config"

interface Props {
	config: SegmentConfig;
}

export default function Header({ config }: Props) {
	const title = config.segment.charAt(0).toUpperCase() + config.segment.slice(1);
	const copyright = `© 2024 ACME ${title}, Inc. or its affiliates. All rights reserved.`;

	return (
		<footer data-selector="footer" role="navigation" aria-label="footer">
			<ContentLayout
				defaultPadding={true}
				maxContentWidth={1000}
				disableOverlap
			>
				<ColumnLayout columns={2} variant="text-grid">
					<div>
						<Link href={`/${config.segment}/`}>About Us</Link>&nbsp;|&nbsp;
						<Link href={`/${config.segment}/contact`}>Contact</Link>
					</div>
					<div>{copyright}</div>
				</ColumnLayout>
			</ContentLayout>
		</footer>
	);
}
