export interface SegmentConfig {
	segment: string;
	chatUrl: string;
	hero: Hero;
	pagesRow: PagesRow;
	mediaRow?: MediaRow;
}

interface Hero {
	subtitle: string;
	description: string;
}

interface PagesRow {
	title: string;
}

interface MediaRow {
	title: string;
	children: {
		description: string;
	}[];
}

export interface SegmentConfigMap {
	[key: string]: SegmentConfig;
}

const councilConfig: SegmentConfig = {
	segment: "council", 
	chatUrl: "https://zbewon5l.chat.qbusiness.us-east-1.on.aws/",
	hero: {
		subtitle: "The best place to find your council services and information.",
		description: "We're dedicated to simplifying access and empowering you to engage with your neighborhood."
	},
	pagesRow: {
		title: "Popular Services & Information",
	},
	mediaRow: {
		title: "Visiting Us",
		children: [
			{
				description: "How to find us"
			},
			{
				description: "Our support"
			}
		]
	}
}

const airportConfig: SegmentConfig = {
	segment: "airport",
	chatUrl: "https://rsictpj2.chat.qbusiness.us-east-1.on.aws/",
	hero: {
		subtitle: "The best place to find your airport services and information.",
		description: "Connecting you to the world with seamless journeys and exceptional service."
	},
	pagesRow: {
		title: "Popular Services & Information",
	},
	mediaRow: {
		title: "Visiting Us",
		children: [
			{
				description: "Terminal map"
			},
			{
				description: "Share your excitement with our social media tag #ACMEAirport"
			}
		]
	}
}

export default {
	default: councilConfig,
	council: councilConfig,
	airport: airportConfig,
} as SegmentConfigMap
