import React from 'react';

import {
	Box,
	Container,
	Grid,
	Header,
	Link,
	Button,
	SpaceBetween,
} from "@cloudscape-design/components";
import ContentLayout from "@cloudscape-design/components/content-layout";

export default function Home() {

	return (
		<ContentLayout
			defaultPadding={true}
			maxContentWidth={1000}
			disableOverlap
			header={
				<Header variant="h1" description="Select a service to explore">
					Welcome to ACME Services
				</Header>
			}
		>
			<Grid
				gridDefinition={[
					{ colspan: { default: 12, xxs: 6 } },
					{ colspan: { default: 12, xxs: 6 } },
				]}
			>
				<Link href="/council/">
					<Container header={<Header variant="h2">Council Services</Header>}>
					<SpaceBetween direction="horizontal" size="xs">
						<Box variant="p">
							Access information about local government services, community events, and more.
						</Box>
							<Button>Go to Council Services</Button>
						</SpaceBetween>
					</Container>
				</Link>
				<Link href="/airport/">
					<Container header={<Header variant="h2">Airport Services</Header>}>
					<SpaceBetween direction="horizontal" size="xs">
						<Box variant="p">
							Access information about airport services, facilities, airline details, and more.
						</Box>
							<Button>Go to Airport Services</Button>
						</SpaceBetween>
					</Container>
				</Link>
			</Grid>
		</ContentLayout>
	);
}
