// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
// import css
import "./App.css";

import React from "react";
import { Suspense, useState } from "react";

import { AppLayout } from "@cloudscape-design/components";

import AppRoutes from "./appRoutes";
import Footer from "./page/partial/footer";
import TopNavigation from "./page/partial/topNavigation";
import globalConfig, {SegmentConfig} from "./config";

export default function App() {

	const [segmentConfig, setSegmentConfig] = useState<SegmentConfig>(globalConfig.default);

	return (
		<>
			<Suspense fallback={null}>
				<TopNavigation config={segmentConfig}/>
				<AppLayout
					disableContentPaddings={true}
					toolsHide
					navigationHide
					content={<AppRoutes setSegmentConfig={setSegmentConfig} config={segmentConfig} />}
				></AppLayout>
				<Footer config={segmentConfig} />
			</Suspense>
		</>
	);
}
