// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import markdownRoutes from "./markdownRoutes.json";

import React, { Dispatch, SetStateAction } from 'react';
import { Route, Routes } from "react-router-dom";

import Contact from "./page/council/contact";
import SegmentHome from "./page/segmentHome";
import Home from "./page/home";
import MarkdownPage from "./page/MarkdownPage";
import globalConfig, {SegmentConfig} from "./config";

interface Props {
	setSegmentConfig: Dispatch<SetStateAction<SegmentConfig>>;
	config: SegmentConfig;
}

export default function AppRoutes({ setSegmentConfig, config }:Props) {
	React.useEffect(() => {
		// Get the first path segment from URL (e.g. 'council' from '/council/contact')
		// or use 'default' if no path segment exists
		// Then set the corresponding segment config from globalConfig, falling back to default config
		const segment = window.location.pathname.split('/')[1] || 'default';
		setSegmentConfig(globalConfig[segment as keyof SegmentConfig] || globalConfig.default);
	}, []);

	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/council/contact/" element={<Contact config={config} />} />
			<Route path="/council/" element={<SegmentHome config={config} />} />
			<Route path="/airport/" element={<SegmentHome config={config} />} />
			{markdownRoutes.map((route) => (
				<Route
					key={route}
					path={route}
					element={<MarkdownPage route={route} config={config} />}
				/>
			))}
			{/* Fallback routes come last */}
			<Route path="/council/*" element={<SegmentHome config={config} />} />
			<Route path="/airport/*" element={<SegmentHome config={config} />} />
			<Route path="*" element={<Home />} />
		</Routes>
	);
}
