// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import "@cloudscape-design/global-styles/index.css";

import markdownRoutes from "../markdownRoutes.json";

import React from "react";

import {
	Box,
	Button,
	Container,
	Grid,
	Header,
	Link,
	SpaceBetween,
} from "@cloudscape-design/components";
import ContentLayout from "@cloudscape-design/components/content-layout";

import {SegmentConfig} from "../config"
import Chat from "./partial/chat";

interface Props {
	config: SegmentConfig;
}


type LinkItem = {
	title: string;
	link: string;
};

type Sections = {
	[key: string]: LinkItem[];
};

const formatWord = (str: string) => {
	return capitaliseEachWord(preferAmpersand(str));
};

const capitaliseEachWord = (str: string) => {
	return str
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");
};

const preferAmpersand = (str: string) => {
	return str.replace(/ and /g, " & ");
};

export default function SegmentHome({ config }: Props) {

	const segmentText =
		config.segment.charAt(0).toUpperCase() + config.segment.slice(1);
	document.title = `Home - ACME ${segmentText}`;

	const routes = markdownRoutes.filter((route) =>
		route.startsWith(`/${config.segment}/`)
	);
	const sections: Sections = {};

	routes.forEach((route) => {
		const [, , section, ...rest] = route.split("/");
		if (!sections[section]) {
			sections[section] = [];
		}
		sections[section].push({
			title: rest.join(" ").replace(/-/g, " "),
			link: route,
		});
	});

	const generateGridDefinition = (count: number) => {
		return Array(count).fill({ colspan: { default: 12, xxs: 4 } });
	};

	return (
		<>
			<ContentLayout
				defaultPadding={true}
				maxContentWidth={1000}
				disableOverlap
				headerBackgroundStyle={(mode) =>
					`center center/cover url("/${config.segment}-hero.png")`
				}
				header={
					<Box padding={{ vertical: "xxxl" }}>
						<Grid gridDefinition={[{ colspan: { default: 12, s: 8 } }]}>
							<Container>
								<Box padding="s">
									<Box
										fontSize="display-l"
										fontWeight="bold"
										variant="h1"
										padding="n"
									>
										<SpaceBetween direction="horizontal" size="xs">
										<Box>
										<img src={`/${config.segment}-logo.png`} style={{maxHeight: "3rem", float: "left"}} onError={(e) => e.currentTarget.style.display = 'none'}/>
										</Box>
											ACME {segmentText}
										</SpaceBetween>
									</Box>
									<Box fontSize="display-l" fontWeight="light">
										{config.hero?.subtitle}
									</Box>
									<Box
										variant="p"
										color="text-body-secondary"
										margin={{ top: "xs", bottom: "l" }}
									>
										{config.hero?.description}
									</Box>
									<SpaceBetween direction="horizontal" size="xs">
										<Button variant="primary">Explore Services</Button>
										<Button>Get Involved</Button>
									</SpaceBetween>
								</Box>
							</Container>
						</Grid>
					</Box>
				}
			>
				<SpaceBetween direction="vertical" size="xs">
					<Box variant="h2" padding={{ top: "xxl" }}>
						{config.pagesRow?.title}
					</Box>
					<Box variant="h2" padding={{ top: "xxl" }}>
						<Grid gridDefinition={generateGridDefinition(Object.keys(sections).length)}>
							{Object.entries(sections).map(([section, items]) => (
								<Container
									fitHeight
									key={section}
									header={
										<Header variant="h2" counter={`(${items.length})`}>
											{formatWord(section.replace(/-/g, " "))}
										</Header>
									}
								>
									<ul>
										{items.map((item, index) => (
											<Link key={index} href={item.link}>
												<li>{formatWord(item.title)}</li>
											</Link>
										))}
									</ul>
								</Container>
							))}
						</Grid>
					</Box>
					{config.mediaRow && (
						<>
							<Box variant="h2" padding={{ top: "xxl" }}>
								{config.mediaRow.title}
							</Box>
							<Grid
								gridDefinition={[
									...Array(config.mediaRow.children?.length || 0).fill({ colspan: { default: 12, xxs: 6 } })
								]}
							>
							{config.mediaRow.children?.map((item, index) => (
								<Container
								key={index}
								fitHeight
								media={{
									content: (
									<img src={`/${config.segment}-${index + 1}.png`} />
									),
									height: 200,
									position: "top"
								}}
								>
								{item.description}
								</Container>
							))}
							</Grid>
						</>
					)}
				</SpaceBetween>
			</ContentLayout>

			<Chat
				embedUrl={config.chatUrl}
				embedWidth={600}
				embedHeight={650}
			/>
		</>
	);
}
